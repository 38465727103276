<template>
  <vx-card class="user-sale-invoice-profile"
           :class="{'main-box top-zero-radius md:w-1/2': !userId, 'no-box-shadow': userId > 0}"
           v-if="user && Object.keys(user).length > 0">

    <profile-main-box :profile="getUserProfile">
      <div class="sale-invoice-section">
        <vs-row class="general-fields">
          <vs-col class="sm:pr-2 lg:w-1/4 sm:w-1/2">
            <custom-number-input :label="$t('sales.invoices.labels.id')"
                                 :placeholder="$t('sales.invoices.placeholder.id')"
                                 :is-empty="true"
                                 :styles="{width: '100%'}"
                                 class="mb-0"
                                 :disabled="true"
                                 v-model="invoice.id"/>
          </vs-col>

          <vs-col class="lg:pr-2 sm:pr-0 lg:w-1/4 sm:w-1/2">
            <custom-validate-input :label="$t('sales.invoices.labels.status')"
                                   :class="invoice.status.id === 2 ? 'danger' : ''"
                                   :styles="{width: '100%'}"
                                   class="mb-0"
                                   :disabled="true"
                                   :is-empty="true"
                                   v-model="invoice.status"/>
          </vs-col>

          <vs-col class="sm:pr-2 lg:w-1/4 sm:w-1/2">
            <custom-validate-input :label="$t('sales.invoices.labels.actionType')"
                                   :styles="{width: '100%'}"
                                   class="mb-0"
                                   :disabled="true"
                                   :is-empty="true"
                                   v-model="invoice.actionType"/>
          </vs-col>

          <vs-col class="lg:w-1/4 sm:w-1/2">
            <custom-validate-input :label="$t('sales.invoices.labels.date')"
                                   :styles="{width: '100%'}"
                                   class="mb-0"
                                   :disabled="true"
                                   :is-empty="true"
                                   v-model="invoice.date"/>
          </vs-col>
        </vs-row>
        <vs-row v-if="invoice.coach.id > 0">
          <vs-col class="">
            <div class="coach-info user">
              <span class="title">{{ $t('sales.invoices.labels.coachInfo') }}</span>

              <div @click="imageUserPreviewStatus = true"
                   class="user-image">
                <lazy-image class="profile-avatar cursor-pointer"
                            :src="invoice.coach.avatar"
                            :alt="invoice.coach.name"/>
              </div>

              <image-preview v-if="imageUserPreviewStatus"
                             :image="{src: invoice.coach.avatar, alt: invoice.coach.name}"
                             @closed="imageUserPreviewStatus = false"/>

              <div class="user-info">
                <template v-for="(userInfo, key) in getAsiderCoachProfileInfo">
                  <div class="user-info-item" :key="key">
                    <span class="pre-content">{{ userInfo.label }}</span>
                    <span class="content">{{ userInfo.content }}</span>
                  </div>
                </template>
              </div>
            </div>
          </vs-col>
        </vs-row>

        <div class="products-fields">
          <draggable-dynamic-table v-if="invoice.lines.length"
                                   :in-modal="true"
                                   :active-grid="true"
                                   :fix-screen="true"
                                   :options="options"
                                   :columns="columnsLabel"
                                   :data="invoice.lines"/>
        </div>
        <div class="price-box">
          <div class="price-details">
            <div class="price-details-row">
              <div class="price-details-title">
                <b>{{ $t('sales.invoices.labels.totalPrice') }}</b>
              </div>

              <div class="price-details-price">
                <span>{{ addComma(parseInt(invoice.totalPrice.value)) || 0 }}</span>
                <span>{{ $locale.currency() }}</span>
              </div>
            </div>

            <div class="price-details-row">
              <div class="price-details-title">
                <b>{{ $t('sales.invoices.labels.discount') }}</b>
              </div>

              <div class="price-details-price">
                <span>{{ addComma(parseInt(invoice.discount.value)) || 0 }}</span>
                <span>{{ $locale.currency() }}</span>
              </div>
            </div>

            <div v-if="invoice.status.id === 2" class="price-details-row">
              <div class="price-details-title">
                <b>{{ $t('sales.invoices.labels.reject') }}</b>
              </div>

              <div class="price-details-price">
                <span>{{ addComma(parseInt(invoice.cancelPrice.value)) || 0 }}</span>
                <span>{{ $locale.currency() }}</span>
              </div>
            </div>

            <div class="price-details-row">
              <div class="price-details-title">
                <b>{{ $t('sales.invoices.labels.finalPrice') }}</b>
              </div>

              <div class="price-details-price">
                <span>{{ addComma(parseInt(invoice.finalPrice.value)) || 0 }}</span>
                <span>{{ $locale.currency() }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </profile-main-box>

  </vx-card>
</template>

<script>
import {getUser} from '@/http/requests/users/users'
import ProfileMainBox from '@/components/profileMainBox/profileMainBox1.vue'
import {addComma, checkUserPermissions} from '@/assets/js/functions'
import CustomIcon from '@/components/customIcon/customIcon'
import {getAvatarUrl, getStaticFileUrl} from "@/assets/js/functions";
import CustomNumberInput from "../../../../../components/customInput/customNumberInput";
import CustomValidateInput from "../../../../../components/customInput/customValidateInput";
import {getSaleInvoice} from "../../../../../http/requests/booking/sales";
import LazyImage from "../../../../../components/lazyImage/lazyImage";
import ImagePreview from "../../../../../components/imagePreview/imagePreview";

export default {
  name: 'saleInvoiceModal',
  components: {ImagePreview, LazyImage, CustomValidateInput, CustomNumberInput, CustomIcon, ProfileMainBox},
  props: {
    userId: 0,
    invoiceId: 0
  },
  metaInfo () {
    return {
      title: this.user ? this.$t('users.user.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}) : this.$t('users.user.profile.title')
    }
  },
  data () {
    return {
      user: null,
      imageUserPreviewStatus: false,
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'finalPrice',
          i18n: 'sales.invoices.invoice.table.header.finalPrice',
          width: '110px',
          minWidth: 110,
          type: 'relation',
          relation: '(quantity)[*](unitPrice)',
          relationType: 'price',
        },
        {
          field: 'unitPrice',
          i18n: 'sales.invoices.invoice.table.header.unitPrice',
          width: '110px',
          minWidth: 110,
        },
        {
          field: 'quantity',
          i18n: 'sales.invoices.invoice.table.header.quantity',
          align: 'center',
          width: '50px',
          minWidth: 50,
        },
        {
          field: 'name',
          i18n: 'sales.invoices.invoice.table.header.name',
          align: 'center',
          width: 'calc(100%)',
          minWidth: 150,
        },
        {
          field: 'rowNumber',
          i18n: 'sales.invoices.invoice.table.header.row',
          align: 'center',
          width: '50px',
          minWidth: 50,
          autoIncrement: true,
        }
      ],
      invoice: {
        actionType: {
          value: '',
          isValid: true
        },
        cancelType: 1,
        id: {
          value: '',
          isValid: true
        },
        status: {
          value: '',
          isValid: true
        },
        passed: true,
        user: {
          id: 0,
          name: {
            value: '',
            isValid: true
          },
          avatar: '',
          gender: '',
          phoneNumber: ''
        },
        coach: {
          id: 0,
          name: {
            value: '',
            isValid: true
          },
          avatar: '',
          gender: '',
          phoneNumber: '',
          price: 0
        },
        reserveInfo: {
          avatar: '',
          courtName: '',
          time: '',
          date: '',
          price: ''
        },
        address: {
          value: '',
          isValid: true
        },
        date: {
          value: '',
          isValid: true
        },
        type: '',
        discount: {
          value: '0',
          isValid: true
        },
        cancelPrice: {
          value: '0',
          isValid: true
        },
        totalPrice: {
          value: '0',
          isValid: true
        },
        finalPrice: {
          value: '0',
          isValid: true
        },
        lines: [],
        selectedSaleAddon: []
      },
      invoiceProcesses: [
        {
          label: this.$t('sales.invoices.statusTypes.active'),
          value: 1
        },
        {
          label: this.$t('sales.invoices.statusTypes.canceled'),
          value: 2
        },
        {
          label: this.$t('sales.invoices.statusTypes.activePassed'),
          value: 3
        },
        {
          label: this.$t('sales.invoices.statusTypes.canceledBySystem'),
          value: 4
        },
      ],
      invoiceType: [
        {
          label: this.$t('sales.invoices.actionTypes.present'),
          value: 1
        },
        {
          label: this.$t('sales.invoices.actionTypes.tel'),
          value: 2
        },
        {
          label: this.$t('sales.invoices.actionTypes.internet'),
          value: 3
        }
      ],
    }
  },
  created () {
    this.getUser()
    this.getSaleInvoice()
  },
  computed: {
    getUserProfile () {
      const user = {
        avatar: this.user.avatar ? getAvatarUrl(this.user.avatar) : this.user.general.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png'),
        name: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
        general: this.user.general,
        asiderAvatar: [
          {
            content: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
            preContent: this.user.general.gender === 1 ? this.$t('users.genderTypes.dynamicMan') : this.user.general.gender === 2 ? this.$t('users.genderTypes.woman') : ''
          },
          {
            content: this.user.general.phoneNumber.value,
            preContent: this.$t('users.user.profile.asiderAvatar.phoneNumber')
          },
          // {
          //   content: this.user.general.character ?  this.user.general.character.name : '',
          //   preContent: this.$t('users.user.profile.asiderAvatar.character')
          // },
          {
            content: this.user.general.dailyDebt > 0 ? `${addComma(this.user.general.dailyDebt)} ${this.$locale.currency()}` : this.$t('users.user.labels.notHave'),
            preContent: this.$t('users.user.profile.asiderAvatar.todayDebt'),
            color: this.user.general.dailyDebt > 0 ? 'danger' : 'success'
          }
        ],
        details: [],
      }

      return user
    },
    getAsiderCoachProfileInfo() {
      const info = [
        {
          label: this.$t('sales.invoices.labels.fullName'),
          content: this.invoice.coach.name.value
        },
        {
          label: this.$t('sales.invoices.labels.phoneNumber'),
          content: this.invoice.coach.phoneNumber
        },
        {
          label: `${this.$t('sales.invoices.labels.coachPrice')}: `,
          content: `${addComma(this.invoice.coach.price)} ${this.$locale.currency()}`
        }
      ]
      return info
    }
  },
  methods: {
    getUser () {
      getUser(this.userId || this.$route.params.id).then(res => {
        const user = res.data.data

        this.user = {
          general: {
            name: {
              value: user.name,
              isInvalid: ''
            },
            lastName: {
              value: user.family,
              isInvalid: ''
            },
            gender: user.gender || '',
            character: user.character,
            phoneNumber: {
              value: user.phone_number,
              isInvalid: ''
            },
            dailyDebt: user.daily_debt || 0
            // type: type,
          },
          avatar: user.avatar || '',
          access: user.role,
          group: user.group,
          financial: {
            maxDebt: user.max_debt ? user.max_debt : 0,
            balance: user.balance ? user.balance : 0
          }
        }
      })
    },
    getSaleInvoice() {
      getSaleInvoice({id: this.invoiceId}).then(response => {
        const invoice = response.data.data

        const tableRow = []
        tableRow.push({
          id: 0,
          rowNumber: 1,
          name: `${invoice.reserved_time.court.name} - ${invoice.reserved_time.time.start_time.substr(0, 5)} تا ${invoice.reserved_time.time.end_time.substr(0, 5)} - ${invoice.reserved_time.date.split(' ')[0]}`,
          quantity: 1,
          unitPrice: {value: invoice.court_price, type: 'price'},
          finalPrice: {value: '', type: 'price'},
          discount: {value: invoice.discount, type: 'price'},
          court: true
        })
        let price = invoice.court_price
        if (invoice.addons.length > 0) {
          invoice.addons.forEach(addon => {
            tableRow.push({
              id: addon.id,
              rowNumber: tableRow.length + 1,
              name: {
                value: addon.name,
                preContent: {
                  value: addon.required ? this.$t('sales.invoices.labels.required') : '',
                  class: 'text-danger'
                }
              },
              required: addon.required,
              quantity: addon.quantity,
              unitPrice: {value: addon.price, type: 'price'},
              finalPrice: {value: '', type: 'price'}
            })
            price += addon.price * addon.quantity
          })
        }
        this.invoice.totalPrice = {
          value: price,
          isValid: true
        }
        this.invoice.cancelPrice = {
          value: invoice.cancel_price,
          isValid: true
        }
        this.invoice.finalPrice.value = invoice.total_price
        this.invoice.lines = tableRow
        // const row_index = this.columnsLabel.map((e) => {
        //   return e.field
        // }).indexOf('rowNumber')
        // this.columnsLabel[row_index].footer.value = this.invoice.lines.length

        this.invoice.discount.value = invoice.discount.toString()
        this.invoice.id = {value: invoice.invoice_number || invoice.id, isValid: true}
        this.invoice.date = {value: invoice.invoice_date, isValid: true}
        this.invoice.user = {
          id: invoice.user.id,
          name: {
            value: `${invoice.user.name} ${invoice.user.family}`,
            isValid: true
          },
          avatar: invoice.user.avatar ? getAvatarUrl(invoice.user.avatar) : invoice.user.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png'),
          gender: invoice.user.gender,
          phoneNumber: invoice.user.phone_number
        }
        this.invoice.coach = {
          id: invoice.coach ? invoice.coach.id : 0,
          name: {
            value: invoice.coach ? `${invoice.coach.name} ${invoice.coach.family}` : '',
            isValid: true
          },
          avatar: invoice.coach && invoice.coach.avatar ? getAvatarUrl(invoice.coach.avatar) : require('@/assets/images/man-avatar.png'),
          phoneNumber: invoice.coach ? invoice.coach.phone_number : '',
          price: invoice.coach ? invoice.coach.price : 0
        }
        this.invoice.reserveInfo = {
          avatar: invoice.reserved_time.court.avatar ? getAvatarUrl(invoice.reserved_time.court.avatar) : require('@/assets/images/no-image.png'),
          courtName: invoice.reserved_time.court.name || '',
          time: `${invoice.reserved_time.time.start_time.substr(0, 5)} ${this.$t('sales.invoices.labels.until')} ${invoice.reserved_time.time.end_time.substr(0, 5)}`,
          date: invoice.reserved_time.date.split(' ')[0],
          price: invoice.court_price
        }
        let status = ''
        if (invoice.status === 1) {
          if (invoice.passed) {
            status = this.invoiceProcesses[2]
          } else {
            status = this.invoiceProcesses[0]
          }
        } else if (invoice.status === 2) {
          status = this.invoiceProcesses[1]
        }

        this.invoice.status = {id: invoice.status, value: status.label, isValid: true}
        this.invoice.actionType = {
          id: invoice.action_type,
          value: this.getInvoiceActionType(invoice.action_type),
          isValid: true
        }

        if (invoice.address) {
          this.invoice.address = {
            value: `${invoice.address.province}, ${invoice.address.city}, ${invoice.address.address}`,
            isValid: true
          }
        }

        switch (invoice.action_type) {
          case 1:
            this.invoice.type = this.$t('sales.invoices.actionTypes.shortPresent')
            break

          case 2:
            this.invoice.type = this.$t('sales.invoices.actionTypes.shortTel')
            break

          case 3:
            this.invoice.type = this.$t('sales.invoices.actionTypes.shortInternet')
            break
        }

        const delete_index = this.columnsLabel.map(e => e.field).indexOf('delete')
        if (delete_index > -1 && (invoice.status === 2 || !checkUserPermissions('invoice.cancel_addon'))) {
          this.columnsLabel.splice(delete_index, 1)
        }
      })
    },
    getInvoiceActionType(id) {
      return this.invoiceType[id - 1].label
    },
    handleClick (id) {
      document.getElementById(id).click()
    },

    addComma (value) {
      return addComma(value)
    }
  },
}
</script>

<style lang="scss">
.user-sale-invoice-profile {
  height: 100%;

  .vx-card__collapsible-content {
    height: 100%;
  }

  .vx-card__body {
    padding: 0 !important;
    height: 100%;

    .coach-info {
      padding: 0.5rem;
    }

    .profile-main-box2 {
      padding: 0;
      height: 100%;

      .profile-header-info {
        border-radius: 0.5rem;

        .profile-avatar {
          border-radius: 0.5rem;
        }
      }

      .sale-invoice-section {
        height: calc(100% - 205px);
        max-width: 75%;
        margin: auto;

        @media screen and (max-width: 500px) {
          max-width: 90%;
        }

        .general-fields {
          background: #cecece22;
          border: 1px solid #cecece;
          border-radius: 0.5rem;
          padding: 0 5px 5px;
          margin-top: 10px;

          .custom-validate-input label {
            background-color: #ffffff;
          }

          .custom-validate-input.danger {
            label {
              background-color: #fad8d8;

              .input-label {
                background: linear-gradient(180deg, #f8f8f8, #fad8d8);
              }
            }
          }

          .custom-validate-input label span.is-focus,
          .custom-number-input label span.is-focus {
            //backdrop-filter: sepia(1);
            //background: linear-gradient(180deg, #f8f8f8, #ffffff);
          }
        }


        .owner-info, .coach-info {
          position: relative;
          margin: 15px 0 0;
          height: calc(100% - 15px);
          border: 1px solid #cdcdcd;
          border-radius: 0.5rem;
          display: flex;

          .title {
            font-size: 14px;
            font-weight: bold;
            position: absolute;
            top: -12px;
            left: 10px;
            padding: 0 5px;
            background-color: #ffffff;
          }

          .user-image {
            max-width: 90px;
            max-height: 90px;
            margin-top: 5px;
            border-radius: 0.5rem;
            overflow: hidden;
            display: flex;
            align-items: center;

            .profile-avatar {
              width: 100%;
              object-fit: cover;
            }
          }

          .user-info {
            padding: 0 0.5rem;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;

            .user-info-item {
              width: 100%;
              line-height: 32px;
              border-bottom: 1px solid #cecece;

              &:last-child {
                border-bottom: 0;
              }

              .pre-content {
                font-size: 12px;
                color: #888888;
              }

              .content {
                font-weight: bold;
              }
            }
          }

        }

        .products-fields {
          flex-grow: 1;
          height: calc(100% - 256px) !important;
          min-height: 215px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          .draggable-dynamic-table {

            .new-suggest-modal-button {
              display: none;
            }

            .suggest-modal-button {
              right: 7px !important;
            }
          }
        }

        .price-box {
          padding-bottom: 20px;
        }

        .price-details {
          display: block;
          border: 1px solid #cecece;
          border-radius: .5rem;
          width: 220px;
          margin-top: 10px;
          margin-left: auto;
          text-align: center;
          line-height: 40px;

          .price-details-row {
            display: flex;
            border-bottom: 1px solid #cecece;

            &:last-child {
              border: none;
            }

            .price-details-title {
              width: 50%;
              border-right: 1px solid #cecece;
              background: #cecece50;
            }

            .price-details-price {
              display: flex;
              justify-content: center;
              width: 50%;

              span:first-child {
                margin-right: 5px;
                font-weight: bold;
              }

              span:last-child {

              }
            }
          }
        }
      }
    }
  }
}
</style>
