<template>
  <div class="profile-main-box2">
    <div class="profile-box"
         v-if="profile && Object.keys(profile).length > 0">
      <vs-row class="profile-header-info">
        <div class="profile-image" v-if="profile.avatar">
          <div @click="imagePreviewStatus = true">
            <lazy-image class="profile-avatar cursor-pointer"
                        :src="profile.avatar"
                        :alt="profile.name"/>
          </div>

          <image-preview v-if="imagePreviewStatus"
                         :image="{src: profile.avatar, alt: profile.name}"
                         @closed="imagePreviewStatus = false"/>
        </div>
        <div class="personal-info" v-if="profile.asiderAvatar.length">
          <template v-for="(personalInfo, key) in profile.asiderAvatar">
            <div v-if="personalInfo.content.length > 0" class="personal-info-item">
              <span v-if="personalInfo.preContent" class="pre-content">{{ personalInfo.preContent || '' }}</span>
              <span class="content name" :class="[personalInfo.color ? `text-${personalInfo.color}` : '', personalInfo.classes || '']">
              {{ personalInfo.content }}
            </span>
            </div>
          </template>
        </div>
      </vs-row>

      <template v-if="profile.details.length">
        <vs-row v-for="(detail, detail_index) in profile.details"
                :key="detail_index">

          <div class="profile-details w-auto mx-auto mt-2">

            <vs-icon v-if="detail.icon"
                     class="w-auto inline-block mr-1"
                     :icon="detail.icon"
                     :icon-pack="detail.iconPack"/>

            <div class="w-auto inline-block">
              {{ `${detail.key} ${detail.key ? ':' : ''} ${detail.value}` }}
            </div>
          </div>
        </vs-row>
      </template>
    </div>

    <slot>
      <div class="actions-list-box" v-if="actionsList.length > 0">
        <ul class="actions-list">
          <router-link :to="{name: action.name, params: action.params || {}, query: action.query || {}}"
                       v-for="(action, action_index) in actionsList"
                       v-if="action.hasOwnProperty('permission') ? checkUserPermissions(action.permission) : true"
                       :key="action_index">

            <li class="action" @click="action.hasOwnProperty('event') ? $emit(action.event) : ''">
              <vs-row>
                <div class="action-content">
                  <template v-if="action.iconPack === 'useral'">
                    <div class="action-icon w-1/12">
                      <custom-icon :icon="action.icon"
                                   height="17px"
                                   width="17px"
                                   :color-code="action.colorCode ? action.colorCode : ''"
                                   :color="action.color ? action.color : ''"/>
                    </div>
                  </template>
                  <template v-else>
                    <vs-icon class="w-1/12"
                             :class="[action.color ? `text-${action.color}`:'']"
                             :icon="action.icon"
                             :icon-pack="action.iconPack"/>
                  </template>

                  <div class="action-label w-auto"
                       :class="[action.color ? `text-${action.color}`:'']">
                    {{ $t(action.i18n) || action.header }}
                  </div>

                  <vs-spacer/>

                  <div v-if="action.badge"
                       class="action-badge-box">
                    <div class="action-badge"
                         :class="[action.badge.type !== 'icon' ? `badge-${action.badge.color || 'primary'}`: '']">

                      <vs-icon v-if="action.badge.type === 'icon'"
                               :class="[action.color ? `text-${action.color}`:'']"
                               :icon="action.icon"
                               :icon-pack="action.iconPack"/>

                      <div v-else>
                        {{ action.badge.data }}
                      </div>

                    </div>
                  </div>
                  <div v-if="action.preview" class="action-preview">
                    <span :class="action.preview.color ? `text-${action.preview.color}` : ''">{{ action.preview.value }}</span>
                  </div>
                </div>

                <vs-icon :icon="$vs.rtl ? 'icon-chevron-left' : 'icon-chevron-right'"
                         iconPack="feather"/>
              </vs-row>
            </li>
          </router-link>
        </ul>
      </div>
    </slot>

    <div class="description" v-if="profile.description">
      <p>{{ profile.description }}</p>
    </div>
  </div>
</template>

<script>
import LazyImage from '@/components/lazyImage/lazyImage'
import ImagePreview from '@/components/imagePreview/imagePreview'
import {checkUserPermissions} from "../../assets/js/functions";
import CustomIcon from "../customIcon/customIcon";

export default {
  name: 'profileMainBox',
  components: {
    CustomIcon,
    ImagePreview,
    LazyImage
  },
  props: {
    profile: {
      type: Object,
      default: () => {
        return {}
      }
    },
    actionsList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      imagePreviewStatus: false
    }
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    addComma(val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    }
  }
}
</script>

<style lang="scss">
.profile-main-box2 {
  $divider: #cdcdcd;


  padding: 10px 0;

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .profile-box {
    position: relative;

    .profile-header-info {
      max-width: 75%;
      margin: auto;
      display: flex;
      flex-direction: row;
      background-color: #fff;
      border: 1px solid $divider;
      border-radius: 1rem;

      @media screen and (max-width: 500px) {
        max-width: 90%;
      }

      .profile-avatar {
        border: 1px solid $divider;
        border-radius: 1rem;
        width: 95px;
        height: 95px;
      }

      .personal-info {
        flex-grow: 1;
        /*max-width: 350px;*/
        margin-right: auto;
        border-radius: 1rem 0 0 1rem;
        /*background-color: #fff;*/

        .personal-info-item {
          margin: 0 10px;
          line-height: 30px;
          border-bottom: 1px solid $divider;
          display: flex;

          &:last-child {
            border-bottom: 0;
          }

          .pre-content {
            color: grey;
            font-size: 85%;
            margin-right: 3px;
          }

          .content {
            color: #000000;
            font-weight: 500;
            display: block;
          }
        }
      }
    }


    .profile-name {
      font-size: 15px;
      font-weight: 700;
      margin: 20px auto 0;
    }

    .important-data-list {
      position: absolute;
      height: 80px;
      width: 70% !important;
      left: 0;
      right: 0;
      bottom: -40px;
      margin: auto;
      background: #131314;
      border: 1px solid #333333;
      border-radius: .5rem;
      box-shadow: 0px 4px 10px -3px black;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 85% !important;
      }

      .important-data-item {
        text-align: center;
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: auto !important;
        height: 100%;

        .key {
          color: #999999;
        }

        .value {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .actions-list-box {
    max-width: 75%;
    margin: auto;
    margin-top: 20px;
    border: 1px solid $divider;
    border-radius: 1rem;
    background-color: #ffffff;

    @media screen and (max-width: 500px) {
      max-width: 90%;
    }

    .actions-list {

      a {


        &:last-child {

          li {
            margin-bottom: 0;

            .action-content {
              border-bottom: 0 !important;
            }
          }
        }

        li.action {
          line-height: 50px;
          align-items: center;
          transition: all .3s ease;
          font-weight: 500;
          color: #000;
          /*border: 1px solid #333333;*/
          border-radius: .5rem;
          /*padding: 0 10px;*/
          /*margin-bottom: 10px;*/

          &:hover {
            transform: translateX(5px);
            transition: all .3s ease;
          }

          .action-content {
            display: flex;
            flex-grow: 1;
            padding: 0 10px;
            border-bottom: 1px solid $divider;

            .action-icon {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }

          i {
            display: flex;
            align-items: center;

            &.icon-chevron-left {
              width: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 17px;
              font-weight: 700;
            }
          }

          .action-badge-box {
            display: flex;
            align-items: center;

            .action-badge {
              width: 25px;
              height: 25px;
              line-height: 25px;
              border-radius: 100%;
              text-align: center;
              margin-right: 10px;
              color: #FFFFFF;

              &.badge- {
                &primary {
                  background: rgba(31, 116, 255, 1);
                }

                &success {
                  background: rgba(70, 201, 58, 1);
                }

                &danger {
                  background: rgba(255, 71, 87, 1);
                }

                &warning {
                  background: rgba(255, 186, 0, 1);
                }

                &light {
                  background: rgba(245, 245, 245, 1);
                }

                &dark {
                  background: rgba(30, 30, 0, 1);
                }
              }
            }
          }
        }
      }
    }
  }

  .description {
    width: 70% !important;
    padding: 10px;
    margin: auto;
    line-height: 25px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: .5rem;
    box-shadow: 0px 4px 10px -3px cecece;

    @media (max-width: 767px) {
      width: 85% !important;
    }

    p {
      text-align: justify;
    }
  }
}
</style>
